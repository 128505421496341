import { useEffect, useRef, useState } from 'react';

import MDEditor from '@uiw/react-md-editor';
import { useDispatch, useSelector } from 'react-redux';
import { PanelGroup } from 'react-resizable-panels';

import { Button, Collapse, Input } from 'antd';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Panel, ResizeHandle } from '../../../../components/resizeable';
import { REQUEST_STATUS } from '../../../../constants/status';
import { OutputBox } from '../../components/Box';
import { Console } from '../../components/console';
import {
    clearExecuteResult,
    requestRecordFeedback,
    selectExecuteResult,
    selectRequestRecordFeedbackRecord,
    selectRequestRecordFeedbackResult,
    selectRequestRecordFeedbackStatus,
    setUserInput,
    clearRequestRecordFeedbackStates,
} from '../../problemSlice';

const { TextArea } = Input;

const getOutputContent = (text, outputRef) => {
    if (text === null) {
        return (
            <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                height="100%"
            >
                <Typography textAlign="center">
                    先點擊下方 Run，測試你的程式碼！
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <OutputBox>
                {/* eslint-disable-next-line react/no-danger */}
                <div ref={outputRef} dangerouslySetInnerHTML={{ __html: text }} />
            </OutputBox>
            {
                text === ''
                    ? (
                        <Typography textAlign="center" color="gray">
                            (沒有輸出任何結果，你可能忘記 print 了)
                        </Typography>
                    )
                    : <> </>
            }
        </>
    );
};

export const ConsoleContainer = ({ visible = false }) => {
    const dispatch = useDispatch();
    const executeResult = useSelector(selectExecuteResult);

    const output = (executeResult?.output || [null])[0];
    const recordId = executeResult?.id;
    const outputRef = useRef(null);

    const [showAiAssistantHelp, setShowAiAssistantHelp] = useState(false);
    const requestRecordFeedbackRecord = useSelector(selectRequestRecordFeedbackRecord);
    const requestRecordFeedbackStatus = useSelector(selectRequestRecordFeedbackStatus);
    const requestRecordFeedbackResult = useSelector(selectRequestRecordFeedbackResult);
    const requestRecordFeedbackLoading = requestRecordFeedbackRecord === recordId
    && requestRecordFeedbackStatus === REQUEST_STATUS.LOADING
    && !requestRecordFeedbackResult;
    const AiAssistantHelpMessage = requestRecordFeedbackResult?.message[0].content;

    const handleAiHelp = () => {
        dispatch(requestRecordFeedback({ recordId, type: 'EXECUTE_ERROR', message: outputRef.current.textContent }));
    };

    const handleInput = (e) => {
        dispatch(setUserInput(e.target.value));
    };

    useEffect(() => {
        if (output && !executeResult?.success) {
            setShowAiAssistantHelp(true);
        }
        else {
            setShowAiAssistantHelp(false);
        }
    }, [executeResult]);

    useEffect(() => () => {
        dispatch(clearExecuteResult());
        dispatch(clearRequestRecordFeedbackStates());
    }, []);

    return (
        <Console visible={visible}>
            <PanelGroup
                direction="horizontal"
            >
                <Panel
                    collapsible
                    minSize={20}
                >
                    <Box
                        style={{
                            height: '100%',
                            width: '100%',
                            padding: '8px',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box flex="0 1 auto">
                            <Typography>
                                輸入：
                            </Typography>
                        </Box>
                        <Box flex="1 1 auto">
                            <TextArea
                                style={{
                                    height: '100%',
                                }}
                                onChange={handleInput}
                            />
                        </Box>
                    </Box>
                </Panel>
                <ResizeHandle
                    direction="horizontal"
                    style={{
                        backgroundColor: '#e0e0e0',
                    }}
                />
                <Panel
                    collapsible
                    minSize={20}
                >
                    <Box
                        style={{
                            height: '100%',
                            width: '100%',
                            padding: '8px',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box flex="0 1 auto">
                            <Typography>
                                輸出：
                            </Typography>
                        </Box>
                        <Box flex="1 1 auto">
                            {getOutputContent(output, outputRef)}
                        </Box>
                        {
                            showAiAssistantHelp && (
                                <Collapse ghost size="small">
                                    <Collapse.Panel header="AI 助教小提示">
                                        {
                                            !requestRecordFeedbackResult
                                            || requestRecordFeedbackLoading
                                                ? (
                                                    <Button
                                                        onClick={handleAiHelp}
                                                        disabled={requestRecordFeedbackLoading}
                                                        loading={requestRecordFeedbackLoading}
                                                    >
                                                        請求 AI 助教小提示
                                                    </Button>
                                                )
                                                : (
                                                    <MDEditor.Markdown
                                                        source={AiAssistantHelpMessage}
                                                    />
                                                )
                                        }
                                    </Collapse.Panel>
                                </Collapse>
                            )
                        }
                    </Box>
                </Panel>
            </PanelGroup>
        </Console>
    );
};
